"use client";

import { millisecondsPerDay, secondsPerMinute, millisecondsPerSecond, minutesPerHour } from "@/ts/date-time/date-time";
import type { TimeZoneName } from "@/ts/date-time/date-time.types";
import type { Locale } from "@/ts/locale/locale.types";
import { mapToBcp47Locale } from "@/ts/locale/map-to-bcp47-locale";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo } from "react";
const ChronologyContext = createContext<undefined | UseChronology>(undefined);
export function useChronology(): UseChronology {
  const chronology = useContext(ChronologyContext);
  if (chronology === undefined) {
    // If you reach this statement, you have discovered a programming error :)
    throw new Error("A ChronologyProvider must exist in the component hierarchy.");
  }
  return chronology;
}
type UseChronology = {
  readonly isToday: (utcDate: Date) => boolean;
  readonly toLongDateString: (utcDate: Date) => string;
  readonly toLongWeekdayString: (utcDate: Date) => string;
  readonly toShortDateString: (utcDate: Date) => string;
  readonly toShortDateWithoutYearString: (utcDate: Date) => string;
  readonly toShortTimeString: (utcDate: Date) => string;
  readonly toRelativeDateTimeString: (utcDate: Date) => string;
  readonly utcNow: Date;
};
type ChronologyProviderProps = {
  readonly utcNow: Date;
  readonly timeZone: TimeZoneName;
  readonly locale: Locale;
  readonly children: ReactNode;
};
export function ChronologyProvider({
  children,
  locale,
  timeZone,
  utcNow
}: ChronologyProviderProps) {
  const startOfLocalTodayEpoch = useMemo(() => {
    const startOfLocalToday = new Date(utcNow.toLocaleDateString("en-US", {
      timeZone,
      timeZoneName: "short"
    }));
    return startOfLocalToday.getTime();
  }, [utcNow, timeZone]);
  const isToday = useCallback((utcDate: Date) => {
    const endOfLocalTodayEpoch = startOfLocalTodayEpoch + millisecondsPerDay;
    return startOfLocalTodayEpoch <= utcDate.getTime() && utcDate.getTime() < endOfLocalTodayEpoch;
  }, [startOfLocalTodayEpoch]);
  const longDateStringFormatter = useMemo(() => new Intl.DateTimeFormat(mapToBcp47Locale(locale), {
    dateStyle: "long",
    timeZone
  }), [locale, timeZone]);
  const longWeekdayStringFormatter = useMemo(() => new Intl.DateTimeFormat(mapToBcp47Locale(locale), {
    timeZone,
    weekday: "long"
  }), [locale, timeZone]);
  const shortDateStringFormatter = useMemo(() => new Intl.DateTimeFormat(mapToBcp47Locale(locale), {
    dateStyle: "short",
    timeZone
  }), [locale, timeZone]);
  const shortDateWithoutYearStringFormatter = useMemo(() => new Intl.DateTimeFormat(mapToBcp47Locale(locale), {
    day: "numeric",
    month: "short",
    timeZone
  }), [locale, timeZone]);
  const shortTimeStringFormatter = useMemo(() => new Intl.DateTimeFormat(mapToBcp47Locale(locale), {
    timeStyle: "short",
    timeZone
  }), [locale, timeZone]);
  const toRelativeDateTimeStringFormatter = useMemo(() => typeof Intl.RelativeTimeFormat !== "undefined" ? new Intl.RelativeTimeFormat(locale, {
    style: "long"
  }) : null, [locale]);
  const toLongDateString = useCallback((utcDate: Date) => longDateStringFormatter.format(utcDate), [longDateStringFormatter]);
  const toLongWeekdayString = useCallback((utcDate: Date) => longWeekdayStringFormatter.format(utcDate), [longWeekdayStringFormatter]);
  const toShortDateString = useCallback((utcDate: Date) => shortDateStringFormatter.format(utcDate), [shortDateStringFormatter]);
  const toShortDateWithoutYearString = useCallback((utcDate: Date) => shortDateWithoutYearStringFormatter.format(utcDate), [shortDateWithoutYearStringFormatter]);
  const toShortTimeString = useCallback((utcDate: Date) => shortTimeStringFormatter.format(utcDate), [shortTimeStringFormatter]);
  const toRelativeDateTimeString = useCallback((utcDate: Date) => {
    if (toRelativeDateTimeStringFormatter === null) {
      return "";
    }
    const differenceInMinutes = Math.round((utcNow.getTime() - utcDate.getTime()) / (secondsPerMinute * millisecondsPerSecond));
    if (differenceInMinutes < minutesPerHour) {
      return toRelativeDateTimeStringFormatter.format(-differenceInMinutes, "minutes");
    }
    return toRelativeDateTimeStringFormatter.format(Math.round(-differenceInMinutes / minutesPerHour), "hours");
  }, [toRelativeDateTimeStringFormatter, utcNow]);
  const value = useMemo(() => ({
    isToday,
    toLongDateString,
    toLongWeekdayString,
    toRelativeDateTimeString,
    toShortDateString,
    toShortDateWithoutYearString,
    toShortTimeString,
    utcNow
  }), [isToday, toLongDateString, toLongWeekdayString, toShortDateString, toShortDateWithoutYearString, toShortTimeString, toRelativeDateTimeString, utcNow]);
  return <ChronologyContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ChronologyProvider" data-sentry-source-file="chronology.context.tsx">
      {children}
    </ChronologyContext.Provider>;
}